import React, { useState, useEffect, useContext} from "react";
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/Delete';

import DeleteConnectionModal from "../modals/ConfirmConnectionDelete"

import { UserContext } from "../Layout/UserContext"


import axios from 'axios';
axios.defaults.withCredentials = true

const connectionsSchema = {
    CLASSLINK: {
        image:require("../../style/Logo-Classlink.png")
    },
    CLEVER: {
        image:require("../../style/Logo-Clever.png")
    },
    GOOGLE: {
        image:require("../../style/Logo-Google.png")
    },
    GOOGLEOAUTHTOKEN: {
        image:require("../../style/Logo-Google.png")
    },
    MO365: {
        image:require("../../style/Logo-Microsoft.png")
    },
    PASSWORD: {
        image:require("../../style/Logo-Password.png")
    },
    LTI: {
        image:require("../../style/Logo-IMS.png")
    }
}

const styles = {
    headerBar: {
        width: "100%",
        height:"60px"
    },
    imgLogo: {
        display:"inline-block",
        objectFit: "fill",
        height: "100%"
    },
    deleteButton: {
        display:"inline-block",
        float: 'right'
    },
    fieldHeader: {
        color:"gray"
    },
    fieldValue: {
        color:"red"
    }
}

const ConnectionInfo = ({connection,account,connection_type}) => {
    const {site, setSite} = useContext(UserContext)
    const [modalState, setModalState] = useState(false)
    const openModal = () => {setModalState(true)}
    const closeModal = () => {setModalState(false)}

    return (
        <div style={{display:"inline-block", width:"100%"}}>
            <Paper style={{display:"inline-block", marginTop:5, width:"100%"}}>
                {site.roles.delete ? (
                    <div>
                        <Typography variant="h6" style={{display:"inline-block",marginTop:10}}>ID : {connection.id}</Typography>
                        <Tooltip title="Delete Connection">
                        <IconButton style={styles.deleteButton} aria-label="Delete" component="span" onClick={() => {openModal()}}>
                            <DeleteIcon/>
                        </IconButton>
                        </Tooltip>
                    </div>):(<Typography>ID : {connection.id}</Typography>)}

                <Grid container alignItems="flex-start" justify="left">
                {
                    Object.keys(connection).map(key => {
                        if (key !== "refreshToken" && key !== "id"){
                            return(
                                <Grid item xs={12}>
                                    <Typography>{key} : {connection[key]}</Typography>
                                </Grid>
                            )
                        }
                    })
                }
                </Grid>
            </Paper>
            <DeleteConnectionModal open={modalState} closeModal={closeModal} account = {{accountID:account.id,connectionID:connection.id,"connection_type":connection_type}}/>
        </div>
        )
    }

 const ConnectionCard = (props) => {

    return (
        <Card style={{width:"80%", height:"300px", display:"inline-block", marginTop:20, overflow:"auto"}}>
            <CardContent>
                <div style={styles.headerBar}>
                    {Object.keys(connectionsSchema).includes(props.type.toUpperCase())
                        ? <img style={styles.imgLogo} src={connectionsSchema[props.type.toUpperCase()].image} alt=""/>
                        : <div>{props.type}</div>
                    }

                </div>
                    <Divider/>
                {
                    props.account.connections[props.type].map(connection => {
                        return(<div style={{width:"100%"}}><ConnectionInfo connection={connection} account={props.account} connection_type={props.type}/><Divider/></div>)
                    })
                }

            </CardContent>

    	</Card>
    )
    }

export default ConnectionCard
